import { getItem, postItem } from '../../../api/api-methods';

export const getFilterOptions = async (institutionId) => {
  try {
    const responseData = await getItem(
      `/app-personas/helpers/get-filter-options/${institutionId}`
    );
    if (responseData.e) {
      return { ok: false, message: 'Error al obtener opciones de filtros' };
    }
    return { ok: true, data: responseData };
  } catch (error) {
    return { ok: false, message: error };
  }
};

export const postExportStudentData = async (
  options = {
    filters: {},
    columns: [],
    export_to_pdf: true,
    id_institucion_educativa: null,
  }
) => {
  try {
    const url = '/app-personas/helpers/export-alumnos-data';
    const response = await postItem(url, options);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo generar reporte. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar generar reporte. ' + error,
    };
  }
};
