const studentReportColumns = [
  {
    id: 12,
    name: 'datos_personales__edad',
    text: 'Edad',
  },
  {
    id: 13,
    name: 'datos_personales__genero__dato',
    text: 'Género',
  },
  {
    id: 14,
    name: 'datos_personales__numero_identidad_CURP',
    text: 'CURP',
  },
  {
    id: 11,
    name: 'datos_personales__fecha_nacimiento',
    text: 'Nacimiento',
  },

  {
    id: 15,
    name: 'datos_personales__email',
    text: 'Correo electrónico',
  },
  {
    id: 16,
    name: 'datos_personales__telefono_casa',
    text: 'Teléfono casa',
  },
  {
    id: 17,
    name: 'datos_personales__telefono_movil',
    text: 'Teléfono móvil',
  },
  {
    id: 18,
    name: 'datos_personales__direccion__calle',
    text: 'Calle',
  },
  {
    id: 19,
    name: 'datos_personales__direccion__numero_exterior',
    text: 'Núm. Exterior',
  },
  {
    id: 20,
    name: 'datos_personales__direccion__numero_interior',
    text: 'Núm. Interior',
  },
  {
    id: 21,
    name: 'datos_personales__direccion__codigo_postal',
    text: 'Código postal',
  },
  {
    id: 22,
    name: 'datos_personales__direccion__colonia',
    text: 'Colonia',
  },
  {
    id: 23,
    name: 'datos_personales__direccion__ciudad',
    text: 'Ciudad',
  },
  {
    id: 24,
    name: 'datos_personales__direccion__estado',
    text: 'Estado',
  },
  {
    id: 25,
    name: 'datos_personales__direccion__municipio',
    text: 'Municipio',
  },
  {
    id: 26,
    name: 'datos_personales__direccion__numero_contacto',
    text: 'Número de contacto',
  },
  {
    id: 3,
    name: 'numero_credencial_alumno',
    text: 'Número credencial',
  },
  {
    id: 4,
    name: 'is_estimulacion_temprana',
    text: 'Es estimulación temprana',
  },
  // {
  //   id: 5,
  //   name: 'curp',
  //   text: 'CURP',
  // },
  // {
  //   id: 6,
  //   name: 'nivel_escolar',
  //   text: 'Nivel escolar',
  // },
  {
    id: 7,
    name: 'sub_nivel_escolar',
    text: 'Sub nivel escolar',
  },
  {
    id: 10,
    name: 'usuario__username',
    text: 'Username',
  },

  {
    id: 19,
    name: 'colegiatura__nombre_colegiatura',
    text: 'Nombre colegiatura',
  },
  {
    id: 20,
    name: 'colegiatura__monto',
    text: 'colegiatura',
  },
  {
    id: 22,
    name: 'nivel_educativo__nombre',
    text: 'Nivel educativo',
  },
  {
    id: 1,
    name: 'grupo__nombre_grupo',
    text: 'Grupo',
  },
  {
    id: 23,
    name: 'grupo__salon_clases__nombre_salon',
    text: 'Salón',
  },
  {
    id: 24,
    name: 'grupo__ciclo_escolar__dato',
    text: 'Ciclo escolar',
  },
  {
    id: 25,
    name: 'tutores__usuario__email',
    text: 'Correo electrónico del tutor',
  },
];

export default studentReportColumns;
