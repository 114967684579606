import { mapState } from 'vuex';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle/KnFormSubtitle.vue';
import KnAutoComplete from '../../../shared/components/KnAutoComplete.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import draggable from 'vuedraggable';
import studentReportColumns from '../../helpers/studentReportColumns';
import {
  getFilterOptions,
  postExportStudentData,
} from '../../helpers/schoolControlReportOptions';
import { downloadFileFromUrl } from '../../../shared/helpers/dataUtils';
export default {
  name: 'KnSchoolControlStudentReport',
  components: {
    draggable,
    KnAutoComplete,
    KnBackToTopButton,
    KnFormActionButtons,
    KnFormSubtitle,
  },
  mixins: [],
  props: {},
  data() {
    return {
      cycles: [],
      levels: [],
      syllabus: [],
      periods: [],
      groups: [],
      classroom: [],
      columns: studentReportColumns,
      selectedSchoolYear: null,
      selectedSchoolLevel: null,
      selectedSyllabus: null,
      selectedEvaluationPeriod: null,
      selectedGroup: null,
      selectedClassroom: null,
      selectedColumns: [],
      loading: false,
      generatePDF: 'true',
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1,
      },
      filterOptions: [],
      filters: [],
      report: {
        name: 'listas_de_alumnos.xlsx',
        document: null,
        loading: false,
        error: null,
      },
      // Posibles filtros
      grupo__nombre_grupo: null,
      grupo__ciclo_escolar__dato: null,
      nivel_educativo__nombre: null,
      grupo__plan_estudios__nombre: null,
      grupo__salon_clases__nombre_salon: null,
    };
  },
  computed: {
    ...mapState(['institutionId']),
    dragOptionsChips() {
      return {
        animation: 200,
        group: 'group',
        disabled: false,
        ghostClass: 'ghost',
        sort: true,
      };
    },
    isPDF() {
      return this.generatePDF === 'true' ? true : false;
    },
  },
  watch: {
    institutionId: {
      async handler() {
        await this.fetchData();
      },
    },
    grupo__nombre_grupo(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.addFilter(newValue);
      } else {
        this.removeFilter(oldValue);
      }
    },
    grupo__ciclo_escolar__dato(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.addFilter(newValue);
      } else {
        this.removeFilter(oldValue);
      }
    },
    nivel_educativo__nombre(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.addFilter(newValue);
      } else {
        this.removeFilter(oldValue);
      }
    },
    grupo__plan_estudios__nombre(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.addFilter(newValue);
      } else {
        this.removeFilter(oldValue);
      }
    },
    grupo__salon_clases__nombre_salon(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.addFilter(newValue);
      } else {
        this.removeFilter(oldValue);
      }
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    move(value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },
    change(value) {
      if (value.removed) {
        // insert
        this.selectedColumns.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.selectedColumns[this.dragged.from]
        );
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.selectedColumns.splice(this.dragged.from, 1);
        // RTL
        else this.selectedColumns.splice(this.dragged.from + 1, 1);
      }
    },
    async fetchData() {
      const { ok, data, message } = await getFilterOptions(this.institutionId);
      if (ok) {
        this.filterOptions = data;
      } else {
        console.log('Error: ' + message);
      }
    },
    async generateReport() {
      try {
        this.report.loading = true;
        this.report.document = null;
        this.report.error = null;
        const options = {
          filters: this.filters,
          columns: [...this.selectedColumns],
          export_to_pdf: this.isPDF,
          id_institucion_educativa: this.institutionId,
        };
        // console.log('Options', options);
        const { ok, data, message } = await postExportStudentData(options);
        if (ok) {
          // console.log('Reporte data', data);
          this.report.document = data.documento;
          this.report.name = data.nombre_documento;
          if (!this.isPDF) {
            // window.open(this.report.document, '_blank');
            downloadFileFromUrl(this.report.document, this.report.name);
          }
        } else {
          console.log('Error al generar reporte. ' + message);
          this.report.error = 'Error al generar reporte. ' + message;
        }
      } catch (error) {
        console.error('Error al intentar generar reporte. ' + error);
        this.report.error = 'Error al intentar generar reporte. ' + error;
      } finally {
        this.report.loading = false;
      }
    },
    addFilter(value) {
      const filter = {
        ...this.filterOptions.find((f) => f.options.includes(value)),
      };
      // console.log('finded filter', filter);
      if (filter) filter.options = [value];
      // this.filters.push(filter);
      this.filters[0] = filter;
    },
    removeFilter(value) {
      this.filters = this.filters.filter((f) => !f.options.includes(value));
    },
  },
};
